import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";
import { isUserLoggedIn } from "@/auth/utils";

Vue.use(VueRouter);
function guardRoute(to, from, next) {
  let isAuthenticated = false;
  // this is just an example. You will have to find a better or
  // centralised way to handle you localstorage data handling
  console.log(isUserLoggedIn());
  if (isUserLoggedIn()) {
    isAuthenticated = true;
  } else {
    isAuthenticated = false;
  }
  if (isAuthenticated) {
    next(); // allow to enter route
  } else {
    next("/login"); // go to '/login';
  }
}
const router = new VueRouter({
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes: [
    {
      path: "/features",
      name: "features",
      component: () => import("@/views/features/FeaturesListing.vue"),
      meta: {
        requiresAuth: true,
        pageTitle: "Features",
        // breadcrumb: [
        //   {
        //     text: "Features",
        //     active: true,
        //   },
        // ],
      },
    },

    {
      path: "/no-features",
      name: "no-features",
      component: () => import("@/views/NoFeature.vue"),
      props: true,
      meta: {
        requiresAuth: true,
        layout: "full",
      },
    },

    {
      path: "/plans",
      name: "plans",
      component: () => import("@/views/plans/PlansListing.vue"),
      meta: {
        requiresAuth: true,
        // pageTitle: "Plans",
        // breadcrumb: [
        //   {
        //     text: "Features",
        //     active: true,
        //   },
        // ],
      },
    },
    {
      path: "/plan/:id",
      name: "plan",
      component: () => import("@/views/plans/PlanDetailsPage.vue"),
      meta: {
        requiresAuth: true,
        pageTitle: "Plan Details",
        breadcrumb: [
          {
            text: "Plans",
            to: { name: "plans" },
          },
          {
            text: "Detail View",
            active: true,
          },
        ],
      },
    },

    {
      path: "/new-plan",
      name: "new-plan",
      component: () => import("@/views/plans/NewPlanPage.vue"),
      meta: {
        requiresAuth: true,
        breadcrumb: [
          {
            text: "Plans",
            to: { name: "plans" },
          },
          {
            text: "Create Plan",
            active: true,
          },
        ],
      },
    },

    {
      path: "/edit-plan/:id",
      name: "edit-plan",
      component: () => import("@/views/plans/EditPlanPage.vue"),
      meta: {
        requiresAuth: true,
        breadcrumb: [
          {
            text: "Plans",
            to: { name: "plans" },
          },
          {
            text: "Edit Plan",
            active: true,
          },
        ],
      },
    },

    {
      path: "/",
      name: "customers",
      component: () => import("@/views/customers/Customers.vue"),

      meta: {
        pageTitle: "Organizations",
        requiresAuth: true,
        breadcrumb: [
          {
            text: "Organizations",
            active: true,
          },
        ],
      },
    },

    {
      path: "/new-subscription/:id",
      name: "new-subscription",
      component: () => import("@/views/customers/NewSubscription.vue"),
      meta: {
        requiresAuth: true,
        breadcrumb: [
          {
            text: "Organizations",
            to: {
              path: "/",
            },
          },
          {
            text: "New Subscription",
            active: true,
          },
        ],
      },
    },

    {
      path: "/customize-subscription/:id",
      name: "customize-subscription",
      component: () => import("@/views/customers/EditSubscription.vue"),
      meta: {
        requiresAuth: true,
        breadcrumb: [
          {
            text: "Organizations",
            to: {
              path: "/",
            },
          },
          {
            text: "Customize Subscription",
            active: true,
          },
        ],
      },
    },

    {
      path: "/customerdetails/:id",
      name: "customerdetails",
      component: () => import("@/views/customers/Customer_members.vue"),
      meta: {
        pageTitle: "Customer Details",
        requiresAuth: true,
        breadcrumb: [
          {
            text: "Customer Details",
            active: true,
          },
        ],
      },
    },
    {
      path: "/subsidiarydetails/:id",
      name: "subsidiarydetails",
      component: () =>
        import("@/views/customers/subsidiary/SubsidiaryDetails.vue"),
      meta: {
        pageTitle: "Subsidiary Details",
        requiresAuth: true,
        breadcrumb: [
          {
            text: "Subsidiary Details",
            active: true,
          },
        ],
      },
    },

    {
      path: "/addrole",
      name: "addrole",
      component: () => import("@/views/roles/addrole.vue"),
      meta: {
        requiresAuth: true,
        pageTitle: "Add Role",
        breadcrumb: [
          {
            text: "Add",
            active: true,
          },
        ],
      },
    },
    {
      path: "/adduser",
      name: "adduser",
      component: () => import("@/views/usermanagment/adduser.vue"),
      meta: {
        pageTitle: "Add new user",
        requiresAuth: true,
        breadcrumb: [
          {
            text: "Add",
            active: true,
          },
        ],
      },
    },
    {
      path: "/editrole/:id",
      name: "editrole",
      component: () => import("@/views/roles/editrole.vue"),
      meta: {
        requiresAuth: true,
        pageTitle: "Role details",
        breadcrumb: [
          {
            text: "Role",
            active: true,
          },
        ],
      },
    },

    {
      path: "/userdetail/:id",
      name: "userdetail",
      component: () => import("@/views/usermanagment/userdetail.vue"),
      meta: {
        requiresAuth: true,
        pageTitle: "User details",
        breadcrumb: [
          {
            text: "User",
            active: true,
          },
        ],
      },
    },

    {
      path: "/deleted-users",
      name: "deleted-users",
      component: () => import("@/views/DeletedUsers/Listing.vue"),
      meta: {
        pageTitle: "Deleted Users",
        requiresAuth: true,
      },
    },

    {
      path: "/usermanagement",
      name: "usermanagement",
      component: () => import("@/views/usermanagment/usermanagement.vue"),
      meta: {
        requiresAuth: true,
        pageTitle: "User Management",
        breadcrumb: [
          {
            text: "User Management",
            active: true,
          },
        ],
      },
    },
    {
      path: "/consultants",
      name: "consultants",
      component: () => import("@/views/consultants/Consultants.vue"),
      meta: {
        requiresAuth: true,
        pageTitle: "Secusy+ Users",
        breadcrumb: [
          {
            text: "Secusy+ Users",
            active: true,
          },
        ],
      },
    },
    {
      path: "/customerusers",
      name: "customerusers",
      component: () => import("@/views/customer_users/Customer_users.vue"),
      meta: {
        requiresAuth: true,
        pageTitle: "Customer Users",
        breadcrumb: [
          {
            text: "Customer Users",
            active: true,
          },
        ],
      },
    },
    {
      path: "/consultantdetails/:id",
      name: "consultantdetails",
      component: () => import("@/views/consultants/ConsultantDetails.vue"),
      meta: {
        pageTitle: "Secusy+ User Details",
        requiresAuth: true,
        breadcrumb: [
          {
            text: "Secusy+ User Details",
            active: true,
          },
        ],
      },
    },
    {
      path: "/customeruserdetails/:id",
      name: "customeruserdetails",
      component: () => import("@/views/customer_users/CustomerUserDetails.vue"),
      meta: {
        pageTitle: "Customer User Details",
        requiresAuth: true,
        breadcrumb: [
          {
            text: "Customer User Details",
            active: true,
          },
        ],
      },
    },
    {
      path: "/roles",
      name: "roles",
      component: () => import("@/views/roles/roles.vue"),
      meta: {
        requiresAuth: true,
        pageTitle: "Roles",
        breadcrumb: [
          {
            text: "Roles",
            active: true,
          },
        ],
      },
    },
    {
      path: "/2FA/:id",
      name: "TwoFA",
      component: () => import("@/views/TwoFA.vue"),
      meta: {
        layout: "full",
      },
    },
    {
      path: "/profile",
      name: "profile",
      component: () => import("@/views/Profile.vue"),
      meta: {
        requiresAuth: true,
        pageTitle: "Profile",
        breadcrumb: [
          {
            text: "Profile",
            active: true,
          },
        ],
      },
    },
    {
      path: "/setnewpassword",
      name: "setnewpassword",
      component: () => import("@/views/setnewpassword.vue"),
      meta: {
        layout: "full",
      },
    },
    {
      path: "/resetpassword",
      name: "resetpassword",
      component: () => import("@/views/resetpassword.vue"),
      meta: {
        layout: "full",
      },
    },

    {
      path: "/login",
      name: "login",
      component: () => import("@/views/Login.vue"),
      meta: {
        layout: "full",
      },
    },
    {
      path: "/forgotpassword",
      name: "forgotpassword",
      component: () => import("@/views/forgotpassword.vue"),
      meta: {
        layout: "full",
      },
    },

    {
      path: "/error-404",
      name: "error-404",
      component: () => import("@/views/error/Error404.vue"),
      meta: {
        layout: "full",
      },
    },
    {
      path: "/access-denied",
      name: "error-404",
      component: () => import("@/views/error/AccessDenied.vue"),
      meta: {
        layout: "full",
      },
    },

    {
      path: "*",
      redirect: "error-404",
    },
  ],
});

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById("loading-bg");
  if (appLoading) {
    appLoading.style.display = "none";
  }
});
router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (store.getters["isLoggedIn"]) {
      next();
      return;
    }
    localStorage.setItem("from_url", to.path);
    next("/login");
  } else {
    next();
  }
});

export default router;
